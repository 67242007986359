<template>
  <div class="download-panel__container">
    <div class="download-panel__container-content">
      <div class="download-panel__container-content-icon">
        <el-carousel
          :interval="2000"
          arrow="never"
          :autoplay="true"
          indicatorPosition="none"
        >
          <el-carousel-item v-for="(item, index) in images" :key="index">
            <img :src="isDarkMode ? item.icon.dark : item.icon.light" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="download-panel__container-content-links">
        <div class="download-panel__container-content-links-title">
          <span>{{ translateTitle("4E移动端") }}</span>
          <span class="high-light">{{ translateTitle("助您点石成金") }}</span>
        </div>
        <div class="download-panel__container-content-links-desc">
          {{
            translateTitle(
              "打通多端交易入口，让你在任何时间任何地点都能紧跟财富热点提供多语言支持，让全球客户都能拥有专业金融服务"
            )
          }}
        </div>

        <div class="download-panel__container-content-links-download">
          <template v-for="(item, index) in downloadLinks">
            <el-tooltip
              v-if="item.isQrcode"
              :key="index"
              placement="bottom"
              effect="light"
              :visibleArrow="false"
              :hide-after="0"
              popperClass="qrcode-tooltip"
            >
              <div
                slot="content"
                class="download-panel__container-content-links-download-item-qrcode"
              >
                <qrcode-vue
                  class="down-load-url"
                  v-show="oriUrl"
                  :value="oriUrl"
                  :size="120"
                  level="H"
                ></qrcode-vue>

                <!-- <img :src="item.qrcodeBig" alt="" /> -->
                <div class="tips">{{ translateTitle("扫码下载") }}</div>
              </div>
              <div
                :class="
                  'download-panel__container-content-links-download-item' +
                  (item.isQrcode ? ' isQrcode' : '')
                "
              >
                <div
                  :class="
                    'download-panel__container-content-links-download-item-icon' +
                    (item.isQrcode ? ' isQrcode' : '')
                  "
                >
                  <img
                    :src="
                      downloadLinksImgs[isDarkMode ? 'dark' : 'light'][index]
                    "
                    alt=""
                  />
                </div>
              </div>
            </el-tooltip>
            <div
              v-else
              :key="index"
              @click="onClickDownload(item)"
              :class="
                'download-panel__container-content-links-download-item' +
                (item.isQrcode ? ' isQrcode' : '')
              "
            >
              <div
                :class="
                  'download-panel__container-content-links-download-item-icon' +
                  (item.isQrcode ? ' isQrcode' : '')
                "
              >
                <img
                  :src="downloadLinksImgs[isDarkMode ? 'dark' : 'light'][index]"
                  alt=""
                />
              </div>
              <div
                v-if="!item.isQrcode"
                class="download-panel__container-content-links-download-item-info"
              >
                <!-- <div
                  class="download-panel__container-content-links-download-item-info-label"
                >
                  {{ translateTitle(item.label) }}
                </div> -->
                <div
                  class="download-panel__container-content-links-download-item-info-name"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <img
          src="../../assets/img/home/download/download-bg.png"
          alt=""
          class="download-bg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import commonMixin from "@/components/common/commonMixin.vue";
import d1 from "@/assets/img/home/download/01-dark.png";
import d2 from "@/assets/img/home/download/02-dark.png";
import d3 from "@/assets/img/home/download/03-dark.png";
import d4 from "@/assets/img/home/download/04-dark.png";
import d5 from "@/assets/img/home/download/05-dark.png";
import d1l from "@/assets/img/home/download/01-light.png";
import d2l from "@/assets/img/home/download/02-light.png";
import d3l from "@/assets/img/home/download/03-light.png";
import d4l from "@/assets/img/home/download/04-light.png";
import d5l from "@/assets/img/home/download/05-light.png";
import qrcodeBig from "@/assets/img/home/download/qrcodeBig.png";
import { apiWsUrl } from "@/model/exchange/index";
import { mapGetters } from "vuex";
import { DEVICE_ID, VUE_CHANNEL_ID } from "@/assets/js/stationSetting";
export default {
  props: {},
  components: { QrcodeVue },
  mixins: [commonMixin],
  data() {
    return {
      oriUrl: "",
      images: [
        {
          icon: {
            dark: d1,
            light: d1l,
          },
        },
        {
          icon: {
            dark: d2,
            light: d2l,
          },
        },
        {
          icon: {
            dark: d3,
            light: d3l,
          },
        },
        {
          icon: {
            dark: d4,
            light: d4l,
          },
        },
        {
          icon: {
            dark: d5,
            light: d5l,
          },
        },
      ],
      downloadLinksImgs: {
        dark: [
          require("@/assets/img/home/download/app-dark.png"),
          require("@/assets/img/home/download/google-dark.png"),
          require("@/assets/img/home/download/apk-dark.png"),
          require("@/assets/img/home/download/qrcode-dark.png"),
        ],
        light: [
          require("@/assets/img/home/download/app.png"),
          require("@/assets/img/home/download/google.png"),
          require("@/assets/img/home/download/apk.png"),
          require("@/assets/img/home/download/qrcode.png"),
        ],
      },

      downloadLinks: [
        {
          label: "商店下载",
          name: "App Store",
          // link: "https://apps.apple.com/us/app/4e/id6464002549",
          link: "",
          key: "appstore_url",
          reportKey: "apple",
        },
        {
          label: "商店下载",
          name: "Google Play",
          link: "https://play.google.com/store/apps/details?id=com.foure.app",
          key: "google_url",
          reportKey: "google",
        },
        {
          label: "安装包下载",
          name: "Android APK",
          link: "https://download.hxexchge1.com/app/topcredit.release.3.8.2.1.apk",
          key: "android_url",
          reportKey: "android",
        },
        {
          qrcodeBig: qrcodeBig,
          isQrcode: true,
        },
      ],
    };
  },
  async created() {
    this.initURL();
    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let temp_url = tempUrl.ori_url;
    // app下载二维码埋点
    if (this.userInfo) {
      this.oriUrl = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=${this.userInfo.user_id}`;
    } else {
      this.oriUrl = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=`;
    }
  },
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),
  },
  methods: {
    async initURL() {
      let params = {
        __version: "3.4.3",
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        let downloadLinks = this.downloadLinks;
        const tempLinks = downloadLinks.map((item) => {
          if (data[item.key]) {
            item.link = data[item.key];
          }
          return item;
        });
        this.downloadLinks = [...tempLinks];
      }
    },
    onClickDownload(item) {
      this.reportParams(item.reportKey);
      window.open(item.link, "_target");
    },
    reportParams(origin) {
      this.$sensors.track("App_Download", {
        download_entrance: "PC_Homepage",
        download_click_type: origin,
        invite_code: "",
      });
    },
  },
};
</script>
<style lang="scss">
#app:not(.zh_CN, .zh_HK) {
  .download-bg {
    bottom: -120px;
  }
}
:root[theme="light"] {
  .download-panel__container-content-links-download-item {
    &:hover {
      border-color: #000;
    }
  }
  .el-tooltip__popper.is-light.qrcode-tooltip {
    border: 1px solid var(--Light04-border-c_s_b02, #000);
  }
  .download-panel__container-content-icon {
    border: 2px solid rgba(51, 72, 246, 0.4); // var(--Dark04-text-c_t01, #000);
  }
  .download-panel__container-content-links-title .high-light {
    color: #3348f6;
  }
}
.qrcode-tooltip {
  background: red;
}
.el-tooltip__popper.is-light.qrcode-tooltip {
  border-radius: 12px;
  border: 1px solid var(--Light04-border-c_s_b02, #f0f0f0);
  background: #fff; //var(--Light04-bg-c_m_o05, rgba(51, 72, 246, 0.04));
}
.download-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 0;
  /* height: 498px; */
  background-color: var(--downloadBgColor);
  color: var(--homeFontColor);

  &-content {
    width: 1200px;
    display: flex;
    flex-direction: row;
    &-icon {
      margin-right: 180px;
      border-radius: 38px;
      text-align: center;
      border: 2px solid var(--Dark04-text-c_t01, #fff);
      > * {
        border-radius: 36px;
      }
      .el-carousel__container {
        position: relative;
        height: 560px;
        width: 260px;
      }
      .el-carousel__item {
        border-radius: 32px;
      }
      img {
        width: 260px;
        height: 563.535px;
        border-radius: 32px;
        border-radius: 32px;
        /* border: var(--homeDownloadImgBorder); */
        max-width: inherit;
      }
    }
    &-links {
      font-size: 24px;
      line-height: 36px;
      position: relative;
      .download-bg {
        /* width: 672.898px; */
        /* height: 330.868px; */
        width: 653px;
        /* height: 331px; */
        flex-shrink: 0;
        position: absolute;
        bottom: -10px;
      }
      &-download {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          border: var(--homeGrayBorder);
          padding: 6px 16px;
          border-radius: 50px;
          min-width: 150px;
          &:hover {
            border-color: #fff;
          }
          &.isQrcode {
            border-radius: 50%;
            padding: 16px;
            min-width: auto;
            width: 56px;
            height: 56px;
          }
          cursor: pointer;
          &-qrcode {
            /* background: red; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 120px;
            .down-load-url {
              height: 120px;
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &-img {
              width: 120px;
              height: 120px;
              border: 1px solid var(--Dark04-text-c_t01, #fff);
            }
            &-text {
            }
            > img {
              width: 104px;
              height: 105px;
            }
            .tips {
              color: #000;
              /* PF_font/r/t_r12 */
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              margin-top: 12px;
              text-align: center;
            }
          }
          &-icon {
            width: 20px;
            height: 20px;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            &.isQrcode {
              margin-right: 0px;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
          &-info {
            &-label {
              color: var(--homeFontColor);
              /* PF_font/r/t_r12 */
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            &-name {
              color: var(--homeFontColor);

              /* OS_font/m/t_m14 */
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px; /* 128.571% */
            }
          }

          /* &:last-child {
            margin-right: 0;
            .download-panel__container-content-links-download-item-icon {
              width: 160px;
              height: 160px;
              img {
                width: 120px;
                height: 120px;
              }
            }
          } */
        }
      }
      &-title {
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px; /* 120% */
        .high-light {
          margin-left: 20px;
        }
      }

      &-desc {
        color: var(--homeLightGary);
        width: 648px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 161.111% */
        margin-top: 12px;
      }
    }
  }
}
</style>